import '../assets/Registration.css';
import { TitleBar } from '../components/TitleBar';

export const RegistrationPage = () => {
    return (
        <div>
            <TitleBar title='Registration'/>
            <div className='title'>Youth and High School</div>
            { /*<p>Youth & HS player registration will be on our new platform, TeamSnap. Signing up for a TeamSnap profile is broadly similar to signing up for a Rugby Xplorer profile, but it will be more particular to Silicon Valley Rugby.</p> */}
            <p>Youth & High School registration is handled through two separate systems, Rugby Xplorer and TeamSnap. For legal reasons, these steps must be completed by the player's <b>parent or guardian</b> - players under 18 must not register themselves.</p>
            <p>Using TeamSnap allows us to offer installment payments for Silicon Valley Rugby dues.</p>
            <p>First, register the player to Silicon Valley Rugby with USA Rugby and Rugby NorCal using <a href="https://auth.rugbyxplorer.com.au/login?clientId=portal&codeChallenge=&redirectPath=/">Rugby Xplorer</a>.</p>
            <ol>
                <li>Log In or Create a Rugby Xplorer account</li>
                <li>Click Register on the left sidebar, then Find a Club in the main window</li>
                <li>Select the person you want to register to play, or click Link a New Person </li>
                <li>Search for "Silicon Valley Rugby". Select "Silicon Valley Rugby YOUTH & HS"</li>
                <li>Select Role = "Player"; Type = "Flag Rugby" for U8, "Youth" for U10, U12, or MS, and "High School" for HS; Duration = "Season".</li>
                <li>Fill in the personal information questions, affirm the waivers, and pay for your national and state-level fees. </li>
                <li>You should get a receipt, with your USA Rugby ID#. Record that for the next step.</li>
            </ol>
            <p>Second, sign the player up as a member of Silicon Valley Rugby using <a href="https://registration.teamsnap.com/form/13003">TeamSnap</a>.</p>
            <ol>
                <li>Log In or Create a TeamSnap account: <a href="https://registration.teamsnap.com/form/13003">https://registration.teamsnap.com/form/13003</a> </li>
                <li>Select the Youth or HS person you want to register to play, or click "+Add a household member" to add a player to your household; if adding a household member, skip the Email field: we don't need youth player's email addresses, and you should avoid adding the parent's email address in that field. </li>
                <li>Select a registration option that suits your needs (players eligible for U12 should generally pick U12 rather than MS)</li>
                <li>Fill in the personal information questions (including USA Rugby ID#), affirm the waivers, and pay for your Silicon Valley Rugby membership.</li>
            </ol>
            <p>Welcome to Silicon Valley Rugby! Join us on Thursday nights at Fair Oaks Park for Touch. Pre-season starts October 21st for U8 through Middle School, and after Thanksgiving for High School.</p>
            <div className='title'>Adult</div>
            <p>Adult Registration will be handled through <a href="https://xplorer.rugby/">Rugby Xplorer</a></p>
            <ol>
                <li>Log In or Create an Account</li>
                <li>Click Register and Find a Club</li>
                <li>Search for "Silicon Valley Rugby". Select SENIOR CLUB.</li>
                <li>Select Role "Player". Type "Senior Club". Duration "Season".</li>
                <li>Fill in the personal information questions and pay for your registration.</li>
                <li>Once all of this is complete, you should get a reciept, and you are ready to play Rugby with Silicon Valley!</li>
            </ol>
        </div>
    );
}